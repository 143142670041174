.main-Footer::before {
  opacity: 0.25;
  content: "";
  background-image: url(../../assets/images/bread.png);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 400px;
  height: 258px;
  z-index: -1;
}

.main-Footer::after {
  opacity: 0.25;
  content: "";
  background-image: url(../../assets/images/pizza.png);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 415px;
  height: 262px;
  z-index: -1;
}

.main-Footer {
  width: 100%;
  // background-image: url("/public/");
  background-image: url(../../assets/images/footer.jpg);
  overflow: hidden;
  position: relative;
  padding: 75px 46px;
  clear: both;
  background-repeat: repeat;
  background-size: 50% 50%;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 75px 20px;
  }

  .contact-businessmain {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
    }

    .footer-contact {
      width: 57%;

      .tel-number {
        margin: 15px 0;
      }
    }

    .head-text {
      color: white;
      display: block;
      border-bottom: 1px solid #3d3d3d;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      text-transform: capitalize;
      margin: 0;
      font-family: "Playfair Display", serif;
    }

    .tel-text {
      color: #fff;
      font-size: 1.3em;
      line-height: 1.5em;
      margin: 0;
    }

    .business-hours {
      width: 40%;

      @media (max-width: 991px) {
        width: 100%;
      }

      .capital-text {
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        text-transform: capitalize;
        margin: 0;
      }

      .timeslot {
        font-weight: 700;
        display: flex;
        gap: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        margin-top: 20px;

        pre {
          font-size: 1em;
        }
      }
    }
  }

  .payment-images {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 15px;
    border-top: 1px solid #3d3d3d;
    margin-top: 30px;
    padding: 30px 0;

    .payment-img {
      width: 49px;
      height: 30px;
    }
  }

  .copyright-text {
    text-align: center;
    color: #999999;

    .highlight-text {
      color: #d5a26f;
    }
  }
}
