.gallery-main {
  background-color: #242424;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0px;

  .maxWidth-gallery {
    max-width: 1220px;
    width: 100%;

    .gallery-text {
      color: #e7e7e7;
      font-size: 75px;
      font-weight: bold;
      margin-bottom: -3px;
      text-transform: capitalize;
      font-family: "Playfair Display", serif;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: 991px) {
        font-size: 40px;
      }
    }

    .gallery-main-div {
      margin: 0 auto;
      text-align: center;

      .gall-img {
        width: 282px;
        border-radius: 10px;
        overflow: hidden;
        margin: 5px;

        @media (max-width: 991px) {
          width: 300px;
        }

        @media (max-width: 620px) {
          width: 210px;
        }

        @media (max-width: 450px) {
          width: 150px;
        }
      }
    }
  }
}
