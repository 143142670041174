.sidebar-ul {
  display: block;
  padding-left: 0 !important;
  color: black;
  font-weight: 600;

  .sidebar-li {
    margin: 10px 0;
    font-size: 15px;
    padding: 9px 22px;
    border-right: solid 0px;
    background-color: transparent;
    line-height: 25px;
    border-bottom: 1px solid #ebebeb;
    position: static;
    color: black;
    font-family: inherit;
  }
}
.btn-order {
  background: #ad1215 !important;
  color: #fff !important;
}
