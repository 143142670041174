.top-header {
  padding: 9px 80px;
  background-color: #ad1215;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  i {
    color: white;
    padding: 0 5px;
  }
  .maxwidthHead {
    max-width: 960px;
    width: 100%;
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    padding: 9px 0;
  }
}

.maxWidthHeaderMain {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.icons {
  color: #ffffff;
  width: 20px;
  cursor: pointer;
}
.main-header {
  min-height: 80px;
  background-color: #242424;

  display: flex;
  justify-content: space-between;
  /* padding: 0 80px; */
}
.logo-img {
  width: 300px;
  height: 80px;
}
img {
  height: 100%;
  width: 100%;
}
.nav-links {
  display: flex;
  gap: 30px;
}
.header-ul {
  list-style: none;
  gap: 30px;
  color: #ffffff;
  align-items: center;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  margin: 0 !important;
  font-weight: 600;
  display: flex;

  .header-cta a {
    text-shadow: #ad1215 2px 5px !important;
    padding-right: 20px;
  }
}
.menu-icon {
  display: none;
}
@media (max-width: 992px) {
  .top-header {
    justify-content: center;
    padding: 15px 0;
  }
  .logo-img {
    width: 240px;
    height: 60px;
  }
  .menu-icon {
    display: block;
    display: flex;
    align-items: center;
    width: 30px;
  }
  .nav-links {
    display: none;
  }
  .maxwidthHead {
    width: 100% !important;
    max-width: 100% !important;
    justify-content: center !important;
  }
}

@media (max-width: 1024px) {
  .main-header {
    padding: 0 10px;
  }
}
