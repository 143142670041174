.react-multi-carousel-track {
  color: black;
  max-height: 576px;
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .react-multi-carousel-dotList, /* Hide the dots (pagination) */
    .react-multiple-carousel__arrow {
    /* Hide the arrows */
    display: none !important;
  }
}
