.main-contact {
  padding-bottom: 50px;
  background-color: #242424;

  .maxWidthMainContact {
    max-width: 1165px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    .contact-text {
      color: #e7e7e7;
      font-size: 75px;
      font-weight: bold;
      margin-bottom: -3px;
      text-transform: capitalize;
      font-family: "Playfair Display", serif;
      clear: both;
      line-height: 1.4em;
      text-align: center;
      padding: 20px 0;
    }
    .tel-yellow {
      color: #ff0;
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0;
    }
    .lunch-div {
      padding: 12px 0;

      .italic-text {
        font-size: 1em;
        color: white;
        margin: 0;
        font-style: italic;
      }

      .timing-text-main {
        font-size: 1em;
        color: white;
        margin: 0;
      }
    }
    .address {
      font-size: 20px;
      font-weight: bold;
      color: white;
      margin: 0;
    }
    .order-online-contact {
      background: #a41f24;
      color: #fff;
      padding: 0.3em 0.6em;
      border: none;
      outline: none;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .map {
      padding-bottom: 15px;
    }
    .border {
      border-bottom: 1px solid #3d3d3d;
      margin-top: 30px;
    }

    @media (max-width: 1200px) {
      .main-contact {
        padding: 20px !important;
        padding-bottom: 50px !important;
        .maxWidthMainContact {
          padding: 0 15px !important;
        }
      }
    }
    @media (max-width: 600px) {
      .contact-text {
        font-size: 50px !important;
      }
      .maxWidthMainContact {
        padding: 0 15px !important;
      }
      .main-contact {
        padding: 20px !important;
      }
    }
    @media (max-width: 300px) {
      .main-contact {
        padding: 20px !important;
      }
    }
  }
}
