@import url("https://fonts.cdnfonts.com/css/playfair-display");
.orderNow-div {
  width: 100%;
  background-color: #ad1215;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 45px;
  flex-basis: 200px;
  justify-content: space-evenly;
  flex-wrap: wrap;

  > div {
    text-align: center;
  }

  .restaurant-name {
    font-weight: 700;
    font-size: 28px;
    font-family: "Playfair Display" !important;
    color: #fff;
  }
  .big-online-btn {
    color: #ad1215;
    background: #f3b67c;
    font-size: 1.6em;
    padding: 0.5em 1.3em 0.5em;
    border: 0 none;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #d8a16c;
    position: relative;
    z-index: 5;
    -webkit-transition: 0.25s ease-in;
    transition: 0.25s ease-in;
    border-radius: 0px;
    border-radius: 30px;
    color: #ad1215;
    font-weight: bold !important;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }
  .big-online-btn:hover {
    color: #fff;
    transition: 0.25s ease-in;
  }
}

.about-us-main {
  background-color: #242424;
  color: white;
  padding: 30px 24px;
  display: flex;
  justify-content: space-between;
}

.maxWidth {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.about-us {
  width: 45%;

  .description {
    color: #e7e7e7;
    margin: 15px 0;
    padding-right: 20px;
  }
  .about-text {
    font-family: inherit;
    font-size: 20px;
    color: #e7e7e7;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  pre {
    font-size: 1em;
  }
  .telephone {
    margin: 0;
    padding: 0;
    padding: 6px 15px;
    font-size: 1.4em;
    line-height: 0.5em;
    color: #d8a16c;
  }
  .lunch-main {
    margin-top: 20px;
  }
}

.images-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Playfair Display", serif;
  text-align: center;

  .enjoy-text {
    font-size: 125px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    color: #d8a16c;
    margin: 0;
  }

  .the-text {
    font-size: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin: 0;
    color: #616161;
  }

  .delicious-text {
    font-family: "Poppins", sans-serif;
    color: #494949;
    font-size: 80px;
    margin-top: -44px !important;
    font-weight: bold;
    margin: 0;
  }

  .gall-row {
    display: flex;
    width: 100%;
    gap: 10px;
    margin: 10px 0;

    .gallery-img {
      max-width: 252px;
      width: 50%;
      border-radius: 5px;
      overflow: hidden;
      transition: transform 0.5s ease;
      display: inline-block;
      overflow: hidden;
      position: relative;
    }
  }
}

.welcome-text {
  font-weight: bold;
  line-height: 1.12em;
  margin: 0 0 15px;
  font-size: 60px;
  font-family: "Playfair Display", serif;
}
.name-text {
  line-height: 1.3em;
  color: #e7e7e7;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}

.italic {
  font-style: italic;
  color: #fff;
  font-size: 1.2em;
  line-height: 1.1em;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.gallery-img:hover {
  transform: scale(1.03);
}

@media (max-width: 1200px) {
  .about-us-main {
    padding: 30px 10px !important;
  }
}
@media (max-width: 995px) {
  .about-us-main {
    flex-direction: column !important;
    align-items: center !important;
    padding: 30px 10px !important;
  }
  .gallery-img {
    width: auto !important;
  }
  .about-us {
    width: 100% !important;
  }
  .images-gallery {
    width: 100% !important;
  }

  .delicious-text {
    font-size: 40px !important;
    margin-top: -3px;
  }
  .enjoy-text {
    margin-top: 30px !important;
    font-size: 60px !important;
  }
  .the-text {
    font-size: 50px !important;
    margin-bottom: 15px !important;
  }
  .welcome-text {
    font-size: 40px !important;
  }
}

@media (max-width: 600px) {
  .about-us-main {
    padding: 10px !important;
  }
}
